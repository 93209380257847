import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore'

const firebaseApp = initializeApp({
  apiKey: "AIzaSyCkZfLZh63IeAT00b1yUP68sa1kw5OgQTI",
  authDomain: "robo-x-fdfed.firebaseapp.com",
  projectId: "robo-x-fdfed",
  storageBucket: "robo-x-fdfed.appspot.com",
  messagingSenderId: "17468296521",
  appId: "1:17468296521:web:3bbb431cfe0276d7b65e98",
  measurementId: "G-H10J9B2CC0"
})

const auth = getAuth(firebaseApp)
const db = getFirestore(firebaseApp)

export {
  auth,
  db
} 