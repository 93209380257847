import styled, { css } from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  padding: 0 20px;
`

export const Header = styled.div`
  display: flex;
  box-shadow: 0 4px 16px -1px rgba(0, 0, 0, 0.6);
  width: 100vw;
  padding: 8px 0;
  align-items: center;
  justify-content: center;
  background: #027B5B;
  margin-left: -20px;
  position: relative;
`

export const SignOutButton = styled.div`
  position: absolute;
  right: 140px;
  cursor: pointer;
  background-color: #027B5B;

  strong {
    font-size: 16px;
    color: #fff;
  }

  padding: 4px 16px;
  border: 1px solid #fff;
  border-radius: 52px;

  &:hover {
    transition-duration: 0.3s;

    background: #fff;

    strong {
      color: #027B5B;
    }
  }

  @media (max-width: 1080px) {
    right: 12px;
  }
`

export const HeaderContent = styled.div`
  max-width: 1400px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Menu = styled.div`
  display: flex;
  align-items: center;
`

export const MenuOption = styled.h1`
  color: #fff;
  opacity: ${props => props.selected ? 1 : 0.6};
  font-size: 24px;
  position: relative;
  cursor: pointer;

  & + h1 {
    margin-left: 20px;
  }
`

export const Content = styled.div`
  max-width: 1400px;
  margin: 40px auto;
`

export const BotNameSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;

  strong {
    font-size: 20px;
  }

  input {
    margin-top: 12px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #E2DEDE;
    padding-left: 4px;
  }

  @media (max-width: 1080px) {
    width: 280px;
  }
`

export const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  strong {
    font-size: 20px;
  }
`

export const AddFilter = styled.div`
  border: 1px solid rgba(2, 123, 91, 0.6);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin-top: 12px;
  width: 180px;
  cursor: pointer;

  strong {
    margin-left: 8px;
    font-size: 16px;
    color: #027B5B;
  }
`

export const FilterListSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  strong {
    font-size: 20px;
  }
`

export const FilterList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-left: 4px;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    h1 {
      margin-left: 8px;
      font-size: 16px;
      color: #027B5B;
    }

    & + div {
      margin-top: 8px;
    }

    @media (max-width: 1080px) {
      align-items: start;

      svg {
        margin-top: 4px;
      }
    }
  }
`

export const TimeSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  strong {
    font-size: 20px;
  }
`

export const TimeRange = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;

  input {
    -webkit-appearance: none;
    height: 4px;
    background: rgba(50, 50, 50, 0.1);
    border-radius: 10px;
    width: 300px;
    position: relative;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 16px;
      height: 16px;
      background: #027B5B;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 0px 12px #027B5B;
        transition: 0.3s;
      }
    }

    &:before {
      content: '';
      position: absolute;
      width: 2px;
      background: #027B5B;
      top: -4px;
      bottom: -4px;
      left: calc(50% - 1px);
      border-radius: 2px;
    }
  }

  strong {
    margin-left: 12px;
    font-size: 20px;
    color: #027B5B;

    @media (max-width: 1080px) {
      margin-left: 0;
      margin-top: 12px;
    }
  }

  @media (max-width: 1080px) {
    flex-direction: column;
    align-items: start;
  }
`

export const ScoreboardSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  strong {
    font-size: 20px;
  }
`

export const ScoreboardOption = styled.div`
  border: 1px solid #027B5B;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin-top: 12px;
  width: 240px;
  cursor: pointer;

  ${props => 
    props.selected &&
    css`
      background-color: #027B5B;
    `
  }

  strong {
    font-size: 16px;
    color: ${props => props.selected ? '#fff' : '#027B5B'};
  }

  & + div {
    margin-top: 4px;
  }
`

export const ScoreboardExactlyOption = styled.div`
  border: 1px solid #027B5B;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin-top: 4px;
  width: 240px;

  ${props =>
    !props.selected && 
    css`
      cursor: pointer;
    `
  }

  ${props => 
    props.selected &&
    css`
      background-color: #027B5B;
    `
  }

  strong {
    font-size: 16px;
    color: ${props => props.selected ? '#fff' : '#027B5B'};
  }

  div {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 12px;

    strong {
      font-size: 12px;
      color: '#fff';
    }
  }
`

export const ScoreboardChoose = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 8px; // hack necessary

  div {
    strong {
      font-size: 20px;
      color: '#fff';
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 44px;
      margin-top: 0px;
    }
  }
`

export const SaveButton = styled.div`
  border: 1px solid #027B5B;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  width: 180px;
  background-color: #027B5B;
  margin: 64px auto 32px;

  box-shadow: 0px 0px 16px #027B5B;
  cursor: pointer;

  strong {
    font-size: 16px;
    color: #fff;
  }
`
