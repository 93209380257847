import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga4'
import Lottie from 'lottie-react';
import { FiDollarSign, FiFolderPlus, FiGift, FiMessageCircle, FiServer, FiSettings } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import Aos from 'aos';

import { useAuth } from '../../hooks/auth'

import { images, lotties, videos } from '../../assets/index'

import { 
  BannerContainer,
  BannerCTAButton,
  BannerLine,
  Container, 
  Header,
  QualitiesSection,
  Quality,
  QualityContainer,
  QualityCTASection,
  QualityCTAButton,
  SocialProofContainer,
  FAQContainer,
  Question,
  FAQTitle,
  FooterContainer,
  FooterSocialMedia,
  FooterRightsReserved,
  Footer,
  VisualContainer,
  VisualTutorial,
  VisualTutorialDescription,
  VisualTutorialDescriptionText,
  AuthSession,
  AuthButton,
  // PaymentContainer,
  // Card,
  // Price,
  // CTAButton,
  // PopularTag,
  // PaymentTitle,
  // PaymentSubTitle,
  // PaymentMethods,
} from './styles'

const LandingPage = () => {
  const { signIn, signUp, user } = useAuth()

  const navigate = useNavigate()

  const [isMobile, setIsMobile] = useState(false)

  const handleSignIn = async (action) => {
    let result

    if (!user) {
      result = await signIn()
      
      if (!result) return
    }
    
    ReactGA.event({
      category: 'SignIn',
      action
    })
    navigate('/dashboard')
  }

  const handleSignUp = async () => {
    await signUp()
    
    ReactGA.event({
      category: 'SignUp',
      action: 'Criar Conta'
    })
    navigate('/dashboard')
  }

  useEffect(() => {
    ReactGA.event({
      category: 'Observando',
      action: 'Landing Page'
    })
    // ReactGA.send({
    //   hitType: 'pageview',
    //   page: window.location.pathname
    // })
  }, [])

  useEffect(() => {
    if (window.innerWidth < 1080) {
      setIsMobile(true)
    }
  }, [])

  useEffect(() => {
    Aos.init({
      duration: 800,
      delay: 200,
    })
  })

  return (
    <Container>
      <Header>
        <img
          src={images.logoWhite}
          alt='Logo White'
          width={isMobile ? 72 : 92}
        />

        <AuthSession>
          <AuthButton
            onClick={() => handleSignIn('Entrar')}
          >
            <strong>Entrar</strong>
          </AuthButton>

          {!user &&
            <AuthButton
              onClick={handleSignUp}
            >
              <strong>Criar Conta</strong>
            </AuthButton>
          }
        </AuthSession>
      </Header>

      <BannerContainer>
        <BannerLine/>

        <strong>Apostas Lucrativas estão <br/>a um passo de você</strong>

        <h1>Crie seu robô e deixe ele analisar todos os jogos ao vivo a cada minuto</h1>
        
        <BannerCTAButton
          onClick={() => handleSignIn('Adquira')}
        >
          <h1>Adquira</h1>
        </BannerCTAButton>
      </BannerContainer>

      <SocialProofContainer>
        <img
          src={images.socialProof1}
          alt=''
          width={573 * 0.7}
          height={460 * 0.7}
          data-aos="flip-right"
        />

        <img
          src={images.socialProof2}
          alt=''
          width={573 * 0.7}
          height={460 * 0.7}
          data-aos="flip-right"
        />
      </SocialProofContainer>

      <VisualContainer>
        <strong>Tutorial</strong>

        <VisualTutorial>
          <VisualTutorialDescription>
            <VisualTutorialDescriptionText>
              <strong>1.</strong>

              <h1>Adicione estratégias ao seu robô</h1>
            </VisualTutorialDescriptionText>

            <VisualTutorialDescriptionText>
              <strong>2.</strong>

              <h1>Espere ele achar as melhores oportunidades ao vivo</h1>
            </VisualTutorialDescriptionText>

            <VisualTutorialDescriptionText>
              <strong>3.</strong>

              <h1>Receba o alerta no Telegram e faça sua aposta</h1>
            </VisualTutorialDescriptionText>
          </VisualTutorialDescription>

          <video autoPlay loop muted style={{ width: 240 }} >
            <source src={videos.tutorial} type='video/mp4' />
          </video>
        </VisualTutorial>
      </VisualContainer>

      <QualityContainer>
        <QualityCTASection>
          <strong>Clique no botão para <br/> lucrar com o seu Robô:</strong>

          <QualityCTAButton
            onClick={() => handleSignIn('Assinar Agora')}
          >
            <h1>Assinar Agora</h1>
          </QualityCTAButton>

          <Lottie
            style={{
              marginTop: 60,
              width: isMobile ? 300 : 'auto',
              alignSelf: isMobile ? 'center' : 'auto'
            }}
            loop={true}
            autoplay={true}
            animationData={lotties.bot}
          />
        </QualityCTASection>

        <QualitiesSection>
          <Quality>
            <img
              src={images.bulletPointQuality}
              alt='Bullet Point'
              width={isMobile? 24 : 28}
              data-aos="fade-in"
            />

            <div>
              <strong>Rápido</strong>

              <h1>Crie seu Robô em menos de 5 minutos</h1>
            </div>
          </Quality>

          <Quality>
            <img
              src={images.bulletPointQuality}
              alt='Bullet Point'
              width={isMobile? 24 : 28}
              data-aos="fade-in"
            />

            <div>
              <strong>Simples</strong>

              <h1>Após criado o Robô, basta ficar com o celular do seu lado e deixar a mágica acontecer</h1>
            </div>
          </Quality>

          <Quality>
            <img
              src={images.bulletPointQuality}
              alt='Bullet Point'
              width={isMobile? 24 : 28}
              data-aos="fade-in"
            />

            <div>
              <strong>Liberdade de Tempo</strong>

              <h1>Não precisa perder tempo vendo todos os jogos ao vivo, deixe que o Robô analise eles para você</h1>
            </div>
          </Quality>

          <Quality>
            <img
              src={images.bulletPointQuality}
              alt='Bullet Point'
              width={isMobile? 24 : 28}
              data-aos="fade-in"
            />

            <div>
              <strong>Ligado 24/7</strong>

              <h1>O Robô vai ficar procurando por oportunidades 24 horas por dia, 7 dias por semana, ou seja, ligado todo o tempo</h1>
            </div>
          </Quality>
        </QualitiesSection>
      </QualityContainer>

      {/* <PaymentContainer>
        <PaymentTitle>Escolha o seu plano abaixo:</PaymentTitle>
            
        <PaymentSubTitle>Compare os planos e escolha o mais ideal para você</PaymentSubTitle>
            
        <PaymentMethods>
          <Card>
            <h1>Mensal</h1>
            
            <h1>Iniciante</h1>
            
            <Price>
              <strong>R$ 74</strong>
            
              <h1>,99</h1>
            </Price>
              
            <CTAButton
              // isLoading={isLoading}
              // onClick={() => redirectToCheckout(priceMonth)}
            >
              <strong>Assine Agora</strong>
            </CTAButton>
          </Card>
            
          <Card>
            <PopularTag>
              <strong>Popular</strong>
            </PopularTag>
            
            <h1>Trimestral</h1>
            
            <h1>Profissional</h1>
            
            <Price principal>
              <strong>R$ 159</strong>
            
              <h1>,00</h1>
            </Price>
              
            <CTAButton 
              principal
              // isLoading={isLoading}
              // onClick={() => redirectToCheckout(priceSemester)}
            >
              <strong>Assine Agora</strong>
            </CTAButton>
          </Card>
            
          <Card>
            <h1>Semestral</h1>
            
            <h1>Econômico</h1>
            
            <Price>
              <strong>R$ 289</strong>
            
              <h1>,90</h1>
            </Price>
              
            <CTAButton
              // isLoading={isLoading}
              // onClick={() => redirectToCheckout(priceYear)}
            >
              <strong>Assine Agora</strong>
            </CTAButton>
          </Card>
        </PaymentMethods>
      </PaymentContainer> */}

      <FAQTitle>Perguntas Frequentes</FAQTitle>

      <FAQContainer>
        <Question>
          <div>
            <FiServer
              color='#027B5B'
              strokeWidth={3}
              size={14}
            />
          </div>

          <strong>O que é o Radar Tactics?</strong>

          <h1>O Radar Tactics é um programa que busca oportunidades ao vivo em partidas de futebol, a partir das estratégias que você coloca no seu robô.<br/>Quando o robô achar uma oportunidade, ele enviará um alerta para o seu Telegram com o jogo em questão.</h1>
        </Question>

        <Question>
          <div>
            <FiGift
              color='#027B5B'
              strokeWidth={3}
              size={14}
            />
          </div>

          <strong>7 dias de teste</strong>

          <h1>Você poderá desistir de sua compra no período de até 7 dias corridos, tendo o valor pago reembolsado.</h1>
        </Question>

        <Question>
          <div>
            <FiFolderPlus
              color='#027B5B'
              strokeWidth={3}
              size={14}
            />
          </div>

          <strong>Posso mudar meu Robô quantas vezes quiser?</strong>

          <h1>Sim, pode adicionar e remover estratégias no seu robô quando quiser.</h1>
        </Question>

        <Question>
          <div>
            <FiMessageCircle
              color='#027B5B'
              strokeWidth={3}
              size={14}
            />
          </div>

          <strong>Como recebo os alertas dos jogos encontrados?</strong>

          <h1>Pelo Telegram. Conectamos sua conta com nosso aplicativo em apenas um clique!</h1>
        </Question>

        <Question>
          <div>
            <FiDollarSign
              color='#027B5B'
              strokeWidth={3}
              size={14}
            />
          </div>

          <strong>Posso fazer o cancelamento qualquer hora durante a assinatura?</strong>

          <h1>Caso esteja dentro do prazo de 7 dias após a renovação da assinatura, você pode solicitar o cancelamento com direito ao reembolso da mensalidade que foi cobrada.</h1>
        </Question>

        <Question>
          <div>
            <FiSettings
              color='#027B5B'
              strokeWidth={3}
              size={14}
            />
          </div>

          <strong>Tive uma dúvida que não está aqui, como faço para falar com vocês?</strong>

          <h1>Envie sua dúvida para <a href='mailto:tacticsradar@gmail.com'>tacticsradar@gmail.com</a> e nossa equipe entrará em contato com você.</h1>
        </Question>
      </FAQContainer>

      <Footer>
        <FooterContainer>
          <FooterRightsReserved>
            <strong>Radar Tactics</strong>

            <h1>2022 © Todos os direitos reservados.</h1>
          </FooterRightsReserved>

          <FooterSocialMedia>
            {/* <a
              href="https://www.instagram.com/radar_tactics/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={images.instagramLogo}
                alt='Instagram'
                width={isMobile ? 36 : 48}
              />
            </a> */}

            {/* <a
              href="https://twitter.com/betnavtips"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={images.twitterLogo}
                alt='Twitter'
                width={isMobile ? 36 : 48}
              />
            </a> */}

            {/* <a
              href="https://www.youtube.com/watch?v=H5jbeLSPSPg&list=LL&index=3"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={images.youtubeLogo}
                alt='Youtube'
                width={isMobile ? 36 : 48}
              />
            </a> */}
          </FooterSocialMedia>
        </FooterContainer>
      </Footer>
    </Container>
  )
}

export default LandingPage