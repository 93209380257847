import React, { useState, useEffect } from 'react'
import { FiPlusCircle, FiLock, FiArrowRight, FiArrowDown, FiCheckCircle, FiXCircle } from 'react-icons/fi';
import { FaRobot } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import emailjs from '@emailjs/browser'

import { useAuth } from '../../hooks/auth'
import { useBot } from '../../hooks/bot'

import { images, lotties } from '../../assets/index'

import { 
  Container,
  Header,
  SignOutButton,
  SquareContainer,
  SquareBotBlocked,
  SquareTelegram,
  WelcomeText,
  RightSection,
  SquareRemove,
  SquareBot,
  SquareSubscription
} from './styles'

const Dashboard = () => {
  const { user, signOut } = useAuth()
  const { bot, getBot, deleteBot, deleteTelegram, updateSubscription } = useBot()

  const [isSureToUnsubscribe, setIsSureToUnsubscribe] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  const navigate = useNavigate()

  const botRules = {
    scoreboard: {
      away: 2,
      home: 2
    },
    statistic: [
      {
        action: 'shotsTotal',
        comparation: 'more',
        team: 'together',
        value: 8
      }
    ]
  }

  const handleCreateBot = () => {
    if (bot?.isSubscribed) {
      navigate('/create-bot')
    } else {
      alert('Adquira sua assinatura em "Assinar Inscrição" antes de prosseguir com a Criação do seu Robô')
    }
  }

  const handleSubscription = async () => {
    if (bot?.isSubscribed) {
      if (isSureToUnsubscribe) {
        await updateSubscription(user?.uid, false)

        await emailjs.send(process.env.REACT_APP_EMAIL_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLATE_ID, { user_id: user?.uid }, process.env.REACT_APP_EMAIL_PUBLIC_KEY)

        setIsSureToUnsubscribe(false)
      } else {
        alert('Esse efeito será DEFINITIVO \n\nCaso tenha certeza de que quer cancelar sua inscrição basta apertar novamente em "Encerrar Inscrição"')
        
        setIsSureToUnsubscribe(true)
      }
    } else {
      navigate('/payment')
    }
  }

  const handleDeleteBot = async () => {
    if (bot?.botInfo === null || bot?.chatId === '') return

    await deleteBot(user?.uid)

    await getBot(user?.uid) // to refresh the page
  }

  const handleDeleteTelegram = async () => {
    if (bot?.chatId === '') return

    await deleteTelegram(user?.uid)

    await getBot(user?.uid) // to refresh the page
  }

  useEffect(() => {
    if (window.innerWidth < 1080) {
      setIsMobile(true)
    }
  }, [])

  return (
    <Container>
      <Header>
        <img
          src={images.logoWhite}
          alt='Logo White'
          width={isMobile ? 72 : 92}
        />

        <SignOutButton
          onClick={signOut}
        >
          <strong>Sair</strong>
        </SignOutButton>
      </Header>

      {isMobile
        ? <>
            <WelcomeText>Bem vindo,</WelcomeText>
            <WelcomeText>{user?.displayName.split(' ').slice(0, 2).join(' ')}!</WelcomeText>
          </>
        : <WelcomeText>Bem vindo, {user?.displayName.split(' ').slice(0, 2).join(' ')}!</WelcomeText>
      }

      <SquareContainer>
        {bot?.chatId !== '' && bot?.isSubscribed
          ? !bot?.botInfo
            ? <SquareBot
                onClick={handleCreateBot}
              >
                <strong>Crie seu Robô</strong>

                <FaRobot
                  strokeWidth={2}
                  style={{
                    marginTop: 16
                  }}
                />
              </SquareBot>
            : <SquareBot
                isBotConfigured
              >
                <strong>{bot?.botInfo?.name}</strong>

                <Lottie
                  style={{
                    width: isMobile ? 208 : 260
                  }}
                  loop={true}
                  autoplay={true}
                  animationData={lotties.botDashboard}
                />
              </SquareBot>
          : <SquareBotBlocked>
              <h1>Complete a configuração <br/> {isMobile ? 'abaixo' : 'ao lado'} e libere a seção <br/>“Crie seu Robô”</h1>

              {isMobile
                ? <FiArrowDown
                    color='#027B5B'
                    strokeWidth={2}
                    size={isMobile ? 48 : 60}
                    style={{
                      marginTop: 16
                    }}
                  />
                : <FiArrowRight
                    color='#027B5B'
                    strokeWidth={2}
                    size={isMobile ? 48 : 60}
                    style={{
                      marginTop: 16
                    }}
                  />
              }

              <div>
                <FiLock
                  color='#027B5B'
                  strokeWidth={2}
                  size={isMobile ? 240 : 300}
                  style={{
                    opacity: 0.3
                  }}
                />
              </div>
            </SquareBotBlocked>
        }

        <RightSection>
          <SquareTelegram 
            isClickable={bot?.chatId === ''}
          >
            {bot?.chatId === ''
              ? <FiPlusCircle
                  color='#5B027B'
                  strokeWidth={2}
                  size={20}
                />
              : <FiCheckCircle
                  color='#5B027B'
                  strokeWidth={2}
                  size={20}
                />
            }

            {bot?.chatId === ''
              ? <a
                  href={`https://telegram.me/Radar_Tactics_Starter_Bot?start=${user?.uid}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Conectar ao Telegram
                </a>
              : <strong>Telegram Conectado</strong>
            }
          </SquareTelegram>

          <SquareSubscription
            onClick={handleSubscription}
            isSubscribed={bot?.isSubscribed}
          >
            {
              bot?.isSubscribed
                ? <>
                    <FiXCircle
                      color='#cf142b'
                      strokeWidth={2}
                      size={20}
                    />

                    <strong>Encerrar Inscrição</strong>
                  </>
                : <>
                    <FiPlusCircle
                      color='#5B027B'
                      strokeWidth={2}
                      size={20}
                    />
    
                    <strong>Assinar Inscrição</strong>
                  </>
            }
          </SquareSubscription>

          <SquareRemove
            onClick={handleDeleteBot}
            isBotEmpty={bot?.botInfo === null || bot?.chatId === '' || !bot?.isSubscribed}
          >
            <FiXCircle
              color='#cf142b'
              strokeWidth={2}
              size={20}
            />

            <strong>Deletar Robô</strong>
          </SquareRemove>
                 
          <SquareRemove
            onClick={handleDeleteTelegram}
            isTelegramEmpty={bot?.chatId === ''}
          >
            <FiXCircle
              color='#cf142b'
              strokeWidth={2}
              size={20}
            />

            <strong>Desconectar Telegram</strong>
          </SquareRemove>
        </RightSection>
      </SquareContainer>
    </Container>
  )
}

export default Dashboard