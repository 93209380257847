import React from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga4'
import 'aos/dist/aos.css';

import AppProvider from "./hooks/index";

import Routes from './routes/index';

import GlobalStyle from './styles/global'

const TRACKING_ID = 'G-CCWHVYGLPM'
ReactGA.initialize(TRACKING_ID)

function App() {

  return (
    <Router>
      <AppProvider>
        <Routes/>
      </AppProvider>

      <GlobalStyle/>
    </Router>
  );
}

export default App;
