import React, { useEffect, useMemo, useState } from 'react'
// import { loadStripe } from '@stripe/stripe-js'
import Aos from 'aos';

import { images } from '../../assets'

import {
  Container,
  Card,
  Price,
  CTAButton,
  PopularTag
} from './styles'

const stripeKey = 'pk_live_51M5AFwKQH8HiFoHu2wxoUyjt1yQp9KGA56klRQqApQQbW0z2X0vDS4iWBVlUEvCjrrEfxi7T0VI4scMItGZtIQVj00ZjDO1uDu'
// const stripeTestKey = 'pk_test_51M5AFwKQH8HiFoHu0LEdumO3N6PIFZfhI92xFUFv8KwkK9lPNLk2ZYISAWTQ0SU5yXuyR0L2ynffaVi6OwGKtKAB00U13vydHK'

// ns para que serve essa api abaixo
// const stripeSecretKey =' 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'

const price = 'price_1Moa2pKQH8HiFoHuNS8mQuIt'
// const priceTest = 'price_1M6g1zKQH8HiFoHughqMlbuU'

// const priceMonth = 'price_1Msy4tKQH8HiFoHuIIHmkqpI'
// const priceMonth = 'price_1OnZR8KQH8HiFoHuHfmxr7wj'
const priceMonth = 'price_1OpznRKQH8HiFoHuioYJngdV'
// const priceSemester = 'price_1MsyZIKQH8HiFoHugGGOERJP'
const priceTrimestre = 'price_1OnZT7KQH8HiFoHuLS6TxT3a'
// const priceYear = 'price_1Msyd3KQH8HiFoHupComvRMg'
const priceSemester = 'price_1OnZUyKQH8HiFoHuuGbxcTgT'

// let stripePromise;

// const getStripe = () => {
//   if (!stripePromise) {
//     stripePromise = loadStripe(stripeKey);
//   }

//   return stripePromise;
// };

const hotmartLinks = {
  'Mensal': 'https://pay.hotmart.com/V92983999Y?off=o8m4m9ig&checkoutMode=10',
  'Trimestral': 'https://pay.hotmart.com/V92983999Y?off=carw37ky&checkoutMode=10',
  'Semestral': 'https://pay.hotmart.com/V92983999Y?off=ocbpa8dk&checkoutMode=10',
};

const Payment = () => {
  // const [stripeError, setStripeError] = useState(null)
  const [isLoading, setLoading] = useState(false)

  // const checkoutOptions = (item) => ({
  //   lineItems: [{
  //     price: item,
  //     quantity: 1
  //   }],
  //   mode: "subscription",
  //   successUrl: `${window.location.origin}/success`,
  //   cancelUrl: `${window.location.origin}/payment`
  // })

  // const redirectToCheckout = async (item) => {
  //   setLoading(true);
  //   console.log("redirectToCheckout");

  //   const stripe = await getStripe();
  //   const { error } = await stripe.redirectToCheckout(checkoutOptions(item));
  //   console.log("Stripe checkout error", error);

  //   if (error) setStripeError(error.message);
  //   setLoading(false);
  // };

  // if (stripeError) alert(stripeError);

  const redirectToCheckout = (plan) => {
    window.location.href = hotmartLinks[plan];
  };

  const isDesktop = useMemo(() => {
    return window.innerWidth > 1080 ? true : false
  }, [])

  useEffect(() => {
    Aos.init({
      duration: 800,
      delay: 200
    })
  })

  return (
    <Container>
      <img 
        src={images.logo} 
        alt='Logo'
      />

      <Card
        data-aos={isDesktop ? "flip-left" : "zoom-in"}
      >
        <h1>Mensal</h1>

        <h1>Iniciante</h1>

        <Price>
          <strong>R$ 64</strong>

          <h1>,99</h1>
        </Price>
        
        <CTAButton
          isLoading={isLoading}
          onClick={() => redirectToCheckout('Mensal')}
        >
          <strong>Assine Agora</strong>
        </CTAButton>
      </Card>

      <Card
        data-aos={isDesktop ? "zoom-in" : "zoom-out"}
      >
        <PopularTag>
          <strong>Popular</strong>
        </PopularTag>

        <h1>Trimestral</h1>

        <h1>Profissional</h1>

        <Price principal>
          <strong>R$ 159</strong>

          <h1>,00</h1>
        </Price>
        
        <CTAButton 
          principal
          isLoading={isLoading}
          onClick={() => redirectToCheckout('Trimestral')}
        >
          <strong>Assine Agora</strong>
        </CTAButton>
      </Card>

      <Card
        data-aos={isDesktop ? "flip-right" : "zoom-in"}
      >
        <h1>Semestral</h1>

        <h1>Econômico</h1>

        <Price>
          <strong>R$ 289</strong>

          <h1>,90</h1>
        </Price>
        
        <CTAButton
          isLoading={isLoading}
          onClick={() => redirectToCheckout('Semestral')}
        >
          <strong>Assine Agora</strong>
        </CTAButton>
      </Card>
    </Container>
  )
}

export default Payment