import styled, { css } from "styled-components";

export const Container = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 10;
`

export const Content = styled.div`
  background: #fff;
  height: 660px;
  width: 800px;
  border-radius: 40px;
  padding: 32px 40px 0;
  position: relative;

  @media (max-width: 1080px) {
    overflow-x: scroll;
    height: 96%;
    width: 96%;
  }
` 

export const TeamSection = styled.div`
  display: flex;
  flex-direction: column;

  strong {
    font-size: 20px;
  }

  div {
    display: flex;

    @media (max-width: 1080px) {
      flex-direction: column;
      align-items: center;
    }
  }
`

export const TeamOption = styled.div`
  border: 1px solid #027B5B;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin-top: 12px;
  width: 180px;
  cursor: pointer;

  ${props => 
    props.selected &&
    css`
      background-color: #027B5B;
    `
  }

  strong {
    font-size: 16px;
    color: ${props => props.selected ? '#fff' : '#027B5B'};
  }

  & + div {
    margin-left: 16px;

    @media (max-width: 1080px) {
      margin-left: 0px;
      margin-top: 8px;
    }
  }

  @media (max-width: 1080px) {
    padding: 8px;
    border-radius: 16px;
    width: 260px;
  }
`

export const StatisticSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  strong {
    font-size: 20px;
  }
`

export const StatisticOptionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 8px;

  @media (max-width: 1080px) {
    justify-content: center;
  }
`

export const StatisticOption = styled.div`
  border: 1px solid #027B5B;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  margin-top: 4px;
  width: 220px;
  cursor: pointer;

  ${props => 
    props.selected &&
    css`
      background-color: #027B5B;
    `
  }

  strong {
    font-size: 16px;
    color: ${props => props.selected ? '#fff' : '#027B5B'};
  }

  @media (max-width: 1080px) {
    margin-top: 8px;
    padding: 8px;
    width: 260px;
  }
`

export const ComparationSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  strong {
    font-size: 20px;
  }
`

export const ComparationOptionContainer = styled.div`
  display: flex;

  @media (max-width: 1080px) {
    flex-direction: column;
    align-items: center;
  }
`

export const ComparationOption = styled.div`
  border: 1px solid #027B5B;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin-top: 12px;
  width: 180px;
  cursor: pointer;

  ${props => 
    props.selected &&
    css`
      background-color: #027B5B;
    `
  }

  strong {
    font-size: 16px;
    color: ${props => props.selected ? '#fff' : '#027B5B'};
  }

  & + div {
    margin-left: 16px;

    @media (max-width: 1080px) {
      margin-left: 0px;
      margin-top: 8px;
    }
  }

  @media (max-width: 1080px) {
    padding: 8px;
    border-radius: 16px;
    width: 260px;
  }
`

export const QuantitySection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  strong {
    font-size: 20px;

    strong {
      color: #027B5B;
    }
  }

  div {
    display: flex;
    align-items: center;
    padding: 12px;

    strong {
      margin: 0 8px;
      font-size: 28px;
      color: #027B5B;
    }
  }
`

export const FooterSection = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 0 0 40px 40px;
  background: #F5F4F7;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 32px;
  
  h1 {
    font-size: 12px;
    cursor: pointer;
  }

  div {
    margin-left: 32px;
    border: 1px solid #027B5B;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    width: 140px;
    background-color: #027B5B;

    cursor: pointer;

    strong {
      font-size: 16px;
      color: #fff;
    }
  }

  @media (max-width: 1080px) {
    justify-content: center;
    position: relative;
    margin: 8px -40px 0;
  }
`