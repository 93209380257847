import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { FiArrowDownCircle, FiArrowUpCircle, FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../hooks/auth'
import { useBot } from '../../hooks/bot'
 
import { images } from '../../assets/index'
import ModalFilter from '../../components/ModalFilter';
 
import {
  AddFilter,
  BotNameSection,
  Container,
  Content,
  FilterList,
  FilterListSection,
  FilterSection,
  Header,
  HeaderContent,
  Menu,
  MenuOption,
  TimeSection,
  TimeRange,
  ScoreboardSection,
  ScoreboardOption,
  ScoreboardExactlyOption,
  ScoreboardChoose,
  SaveButton,
  SignOutButton
} from './styles'
 
const statisticsDictionary = {
  'Gols': 'Goals', // Only one that i will use a different api call
  'Total de chutes': 'Total Shots', 
  'Defesas do goleiro': 'Goalkeeper Saves', 
  'Chutes para fora': 'Shots off Goal', 
  'Escanteios': 'Corner Kicks', 
  'Faltas': 'Fouls', 
  'Chutes no gol': 'Shots on Goal', 
  'Posse de bola': 'Ball Possession', 
  'Cartões amarelos': 'Yellow Cards', 
  'Chutes dentro da área': 'Shots insidebox', 
  'Precisão dos passes': 'Passes %', 
  'Cartões vermelhos': 'Red Cards'
}
 
const teamDictionary = {
  'Casa': 'Home',
  'Visitante': 'Away',
  'Juntos': 'Together'
}
 
const comparationDictionary = {
  'Menor que': 'Less',
  'Igual a': 'Equal',
  'Maior que': 'More'
}

const scoreboardDictionary = {
  'Jogo Empatado': 'Draw',
  'Time da Casa Vencendo': 'Home',
  'Time Visitante Vencendo': 'Away',
  'Placar Exato': 'Exact'
}
 
const CreateBot  = () => {
  const navigate = useNavigate()

  const { user, signOut } = useAuth()
  const { createBot } = useBot()

  const [isMobile, setIsMobile] = useState(false)

  const [selected, setSelected] = useState('Home')
  const [botName, setBotName] = useState('')
 
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [filter, setFilter] = useState([])
 
  const [scoreboardOptionSelected, setScoreboardOptionSelected] = useState('Jogo Empatado')
 
  const [homeScore, setHomeScore] = useState(0)
  const [awayScore, setAwayScore] = useState(0)
 
  const [time, setTime] = useState(0)
 
  const handleRemoveFilter = useCallback((index) => {
    const filterCopy = [...filter]
 
    filterCopy.splice(index, 1)
 
    setFilter(filterCopy)
  }, [filter])
 
  const filterPhrase = useMemo(() => {
    return filter.map(elem => {
      let team
      if (elem.team === 'Juntos') {
        team = 'O time da casa mais o time visitante devem ter'
      } else if (elem.team === 'Casa') {
        team = 'O time da casa deve ter'
      } else {
        team = 'O time visitante deve ter'
      }
 
      let statisticType
      if (elem.statisticType === 'Posse de bola' || elem.statisticType === 'Precisão dos passes') {
        statisticType = `de ${elem.statisticType.toLocaleLowerCase()}`
      } else if (elem.statisticType === 'Total de chutes') {
        statisticType = 'chutes'
      } else {
        statisticType = elem.statisticType.toLocaleLowerCase()
      }
 
      let comparation
      if (elem.comparation === 'Menor que') {
        comparation = 'menos de'
      } else if (elem.comparation === 'Maior que') {
        comparation = 'mais de'
      } else {
        comparation = 'exatamente'
      }
 
      let quantity
      if (elem.statisticType === 'Posse de bola' || elem.statisticType === 'Precisão dos passes') {
        quantity = `${elem.quantity}%`
      } else {
        quantity = elem.quantity
      }
 
      return team + ' ' + comparation + ' ' + quantity + ' ' + statisticType + '.'
    })
  }, [filter])
 
  const handleSaveBotInfo = async () => {
    if (botName === '' || filter.length === 0){
      alert('Preencha todos os campos')

      return
    }

    const filterWithDictionary = filter.map(elem => ({
      ...elem,
      comparation: comparationDictionary[elem.comparation],
      team: teamDictionary[elem.team],
      statisticType: statisticsDictionary[elem.statisticType],
    }))

    const scoreboard = scoreboardOptionSelected === 'Placar Exato'
                        ? {
                            type: scoreboardDictionary[scoreboardOptionSelected],
                            home: homeScore,
                            away: awayScore
                          }
                        : {
                            type: scoreboardDictionary[scoreboardOptionSelected],
                          }

    const botInfo = {
      name: botName,
      rules: filterWithDictionary,
      time,
      scoreboard
    }

    await createBot(user?.uid, botInfo)

    navigate('/dashboard')
  }

  useEffect(() => {
    if (window.innerWidth < 1080) {
      setIsMobile(true)
    }
  }, [])
 
  return (
    <Container>
      {
        isModalOpen && 
        <ModalFilter
          setIsModalOpen={setIsModalOpen}
          setFilter={setFilter}
        />
      }

      <Header>
        <img
          src={images.logo}
          alt='Logo'
          width={isMobile ? 72 : 92}
        />

        <SignOutButton
          onClick={signOut}
        >
          <strong>Sair</strong>
        </SignOutButton>
      </Header>
 
      <Content>
        <BotNameSection>
          <strong>Nome do seu BOT</strong>
 
          <input
            type='text'
            value={botName}
            onChange={(event) => setBotName(event.target.value)}
            placeholder='Over 0.5 HT'
          />
        </BotNameSection>
 
        <FilterSection>
          <strong>Adicione os filtros para identificar o jogos na qual você quer ser notificado</strong>
 
          <AddFilter
            onClick={() => setIsModalOpen(true)}
          >
            <FiPlusCircle
              color='#027B5B'
              strokeWidth={3}
            />
 
            <strong>Adicionar Filtro</strong>
          </AddFilter>
        </FilterSection>
 
        {filterPhrase.length !== 0 &&
          <FilterListSection>
            <strong>Filtros adicionados</strong>
          
            <FilterList>
              {filterPhrase.map((elem, index) => (
                <div
                  key={elem}
                >
                  <div>
                    <FiMinusCircle
                      color='#027B5B'
                      strokeWidth={3}
                      cursor='pointer'
                      onClick={() => handleRemoveFilter(index)}
                    />
                  </div>
  
                  <h1>{elem}</h1>
                </div>
              ))}
            </FilterList>
          </FilterListSection>
        }
 
        <TimeSection>
          <strong>Tempo de Jogo</strong>
 
          <TimeRange>            
            <input type='range' min='0' max='90' value={time} onChange={(event) => setTime(Number(event.target.value))} />
 
            <strong>{time}' - {time + 5}'</strong>
          </TimeRange>
        </TimeSection>
 
        <ScoreboardSection>
          <strong>Placar</strong>
 
          {['Jogo Empatado', 'Time da Casa Vencendo', 'Time Visitante Vencendo', 'Placar Exato'].map(elem => (
            elem !== 'Placar Exato' 
              ? <ScoreboardOption
                  selected={scoreboardOptionSelected === elem}
                  onClick={() => setScoreboardOptionSelected(elem)}
                  key={elem}
                >
                  <strong>{elem}</strong>
                </ScoreboardOption>
              : <ScoreboardExactlyOption
                  selected={scoreboardOptionSelected === elem}
                  onClick={() => setScoreboardOptionSelected(elem)}
                  key={elem}
                >
                  <strong>{elem}</strong>
 
                  {scoreboardOptionSelected === elem &&
                    <>
                      <div>
                        <strong>Casa</strong>
 
                        <strong>Visitante</strong>
                      </div>
 
                      <ScoreboardChoose>
                        <div>
                          <strong>{homeScore}</strong>
 
                          <div>
                            <FiArrowUpCircle
                              cursor='pointer'
                              color={homeScore === 9 ? 'rgba(255, 255, 255, 0.32)' : '#fff'}
                              onClick={() => homeScore < 9 && setHomeScore(homeScore + 1)}
                            />
 
                            <FiArrowDownCircle
                              cursor='pointer'
                              color={homeScore === 0 ? 'rgba(255, 255, 255, 0.32)' : '#fff'}
                              onClick={() => homeScore > 0 && setHomeScore(homeScore - 1)}
                            />
                          </div>
                        </div>
 
                        <div>
                          <div>
                            <FiArrowUpCircle
                              cursor='pointer'
                              color={awayScore === 9 ? 'rgba(255, 255, 255, 0.32)' : '#fff'}
                              onClick={() => awayScore < 9 && setAwayScore(awayScore + 1)}
                            />
 
                            <FiArrowDownCircle
                              cursor='pointer'
                              color={awayScore === 0 ? 'rgba(255, 255, 255, 0.32)' : '#fff'}
                              onClick={() => awayScore > 0 && setAwayScore(awayScore - 1)}
                            />
                          </div>
 
                          <strong>{awayScore}</strong>
                        </div>
                      </ScoreboardChoose>
                    </>
                  }
                </ScoreboardExactlyOption>
          ))}
        </ScoreboardSection>
      </Content>
 
      <SaveButton
        onClick={handleSaveBotInfo}
      >
        <strong>Salvar</strong>
      </SaveButton>
    </Container>
  )
}
 
export default CreateBot