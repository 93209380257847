import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: rgba(2, 123, 91, 1); // 0.8

  img {
    width: 50%;
    opacity: 0.04; // 0.03
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1080px) {
      width: 92%;
    }
  }

  @media (max-width: 1080px) {
    flex-direction: column;
    padding: 12px 0;
    height: 100%;
  }
`

export const Card = styled.div`
  padding: 20px 0 28px;
  height: 360px;
  width: 260px;
  border-radius: 12px;
  /* background: #fff; */
  background: linear-gradient(to top left, #027B5B, #fff);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  position: relative;
  box-shadow: 0px 4px 12px 0px #000;


  h1 {
    font-size: 18px;

    & + h1 {
      font-size: 10px;
      opacity: 0.6;
      letter-spacing: 1.2px;
      text-transform: uppercase;
    }
  }

  &:nth-child(3) {
    /* background: #111727; */
    background: linear-gradient(to bottom right, #111727, #fff);
    height: ${360 * 1.1}px;
    width: ${260 * 1.1}px;
    margin: 0 -6px;
    z-index: 10;

    h1 {
      color: #fff;
    }

    @media (max-width: 1080px) {
      height: 300px;
      width: 260px;
      margin: 12px 0 0 0;
    }
  }

  @media (max-width: 1080px) {
    height: 300px;

    & + div {
      margin-top: 12px;
    }
  }
`

export const PopularTag = styled.div`
  height: 16px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 12px -1px #fff;

  position: absolute;
  top: 12px;
  right: 12px;

  strong {
    font-size: 12px;
    color: #111727;
  }
`

export const Price = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: ${props => props.principal ? 92 : 80}px;

  strong {
    font-size: 44px;
    color: ${props => !props.principal ? '#111727' : '#fff'};
    position: relative;


    &:before {
      content: '';
      position: absolute;
      bottom: -12px;
      left: 12px;
      right: 12px;
      background: ${props => props.principal ? 'linear-gradient(to left, #111727, #fff)' : 'linear-gradient(to right, #027B5B, #fff)'};
      height: 1px;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 24px;
      right: 24px;
      background: ${props => props.principal ? 'linear-gradient(to left, #111727, #fff)' : 'linear-gradient(to right, #027B5B, #fff)'};
      height: 1px;
    }
  }

  h1 {
    font-size: 12px;
    color: ${props => !props.principal ? '#111727' : '#fff'};
  }

  @media (max-width: 1080px) {
    margin-top: 52px;
  }
`

export const CTAButton = styled.div`
  padding: 6px 20px;
  border-radius: 8px;
  background: ${props => !props.principal ? '#111727' : '#fff'};
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  strong {
    font-size: ${props => props.principal ? 20 : 18}px;
    color: ${props => props.principal ? '#111727' : '#fff'};

    @media (max-width: 1080px) {
      font-size: 20px;
    }
  }

  &:hover {
    transition: 0.3s;
    transform: scale(1.06);
  }
`

