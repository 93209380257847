import logo from './images/logo.png'
import homeBanner from './images/home-banner.png'
import socialProof1 from './images/social-proof-1.png'
import socialProof2 from './images/social-proof-2.png'
import bulletPointQuality from './images/bullet-point-quality.png'
import instagramLogo from './images/instagram-logo.png'
import youtubeLogo from './images/youtube-logo.png'
import twitterLogo from './images/twitter-logo.png'
import logoWhite from './images/logo-white.png'

import bot from './lotties/bot.json'
import botDashboard from './lotties/bot-dashboard.json'
import success from './lotties/success.json'
import desktop from './lotties/desktop.json'

import tutorial from './videos/tutorial.mp4'

const images = {
  logo,
  homeBanner,
  socialProof1,
  socialProof2,
  bulletPointQuality,
  instagramLogo,
  youtubeLogo,
  twitterLogo,
  logoWhite
}

const lotties = {
  bot,
  success,
  botDashboard,
  desktop
}

const videos = {
  tutorial
}

export {
  images,
  lotties,
  videos
}