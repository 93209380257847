import React from 'react';

import { BotProvider } from './bot';
import { AuthProvider } from './auth';

const AppProvider = ({ children }) => (
  <BotProvider>
    <AuthProvider>
      {children}
    </AuthProvider>
  </BotProvider>
);

export default AppProvider;