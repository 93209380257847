import React from 'react';
import { Routes as RoutesSwitch, Route } from 'react-router-dom';

import PrivateRoutes from './PrivateRoutes';

import LandingPage from '../pages/LandingPage';
import Dashboard from '../pages/Dashboard';
import Payment from '../pages/Payment';
import CreateBot from '../pages/CreateBot';
import Cancel from '../pages/Cancel';
import Success from '../pages/Success';

const Routes = () => (
  <RoutesSwitch>
    <Route path="/" exact element={<LandingPage/>} />

    <Route element={<PrivateRoutes />}>
      <Route path="/dashboard" element={<Dashboard/>} />
      <Route path="/create-bot" element={<CreateBot/>} />
      <Route path="/payment" element={<Payment/>} />
      <Route path="/cancel" element={<Cancel/>} />
      <Route path="/success" element={<Success/>} />
    </Route>
  </RoutesSwitch>
);

export default Routes;