import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #ebf0f4;
`

export const Content = styled.div`
  width: 480px;
  height: 520px;
  padding: 0 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fff;

  strong {
    margin-top: 16px;
    color: #4a934a;
    font-size: 36px;
  }

  h1 {
    text-align: center;
    margin-top: 16px;
    font-size: 16px;
  }
`

export const LottieContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 24px 24px 12px 24px;
  background: #f8faf5;

  border-radius: 50%;
`