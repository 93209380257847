import React from "react";

import { Container } from "./styles";

const Cancel = () => {

  return (
    <Container>
      <h1>Erro!</h1>
    </Container>
  )
}

export default Cancel