import styled from 'styled-components'

import { images } from '../../assets/index'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`

export const Header = styled.div`
  display: flex;
  box-shadow: 0 4px 16px -1px rgba(0, 0, 0, 0.6);
  width: 100vw;
  padding: 8px 0;
  align-items: center;
  justify-content: center;
  background: #fff;

  position: fixed;
  top: 0;
  z-index: 1000;
`

export const AuthSession = styled.div`
  position: absolute;
  right: 100px;

  display: flex;
  align-items: center;

  @media (max-width: 1080px) {
    right: 0;
    width: 100%;
    padding: 0 12px;
    justify-content: space-between;
  }
`

export const AuthButton = styled.div`
  cursor: pointer;

  strong {
    font-size: 16px;
    color: #027B5B;

    @media (max-width: 1080px) {
      font-size: 14px;
    }
  }

  @media (max-width: 1080px) {
    margin-left: 0;
    padding: 6px 0;
    width: 120px;
    border: 1px solid #027B5B;
    border-radius: 52px;
    display: flex;
    justify-content: center;
  }

  & + div {
    margin-left: 24px;

    padding: 4px 16px;
    border: 1px solid #027B5B;
    border-radius: 52px;

    @media (max-width: 1080px) {
      margin-left: 0;
      width: 120px;
      background-color: rgba(2, 123, 91, 0.9);
      padding: 6px 16px;

      strong {
        color: #fff;
      }
    }
  }

  &:hover {
    transition-duration: 0.3s;
    transform: scale(1.08);
  }
`

export const BannerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${images.homeBanner});
  height: 720px;
  text-align: center;

  @media (max-width: 1080px) {
    background-position: center;
    padding: 4px;
  }

  strong {
    font-size: 56px;
    color: #fff;
    margin-top: 24px;

    @media (max-width: 1080px) {
      font-size: 1.75rem;
    }
  }

  h1 {
    font-size: 20px;
    color: #fff;
    margin-top: 24px;

    @media (max-width: 1080px) {
      font-size: 18px;
    }
  }
`

export const BannerLine = styled.div`
  height: 4px;
  width: 80px;
  background: #5B027B;

  @media (max-width: 1080px) {
    height: 2px;
  }
`

export const BannerCTAButton = styled.div`
  padding: 8px 40px;
  background: #5B027B;
  margin-top: 48px;
  border-radius: 8px;

  cursor: pointer;

  h1 {
    font-size: 28px;
    color: #fff;
    margin-top: 0px;
  }

  &:hover {
    transition-duration: 0.3s;
    box-shadow: 0px 0px 20px rgba(91, 2, 123, 0.8);
  }
`

export const SocialProofContainer = styled.div`
  display: flex;
  margin: 52px auto 0;

  @media (max-width: 1080px) {
    flex-direction: column;
    margin: 36px auto 0;
  }

  img {
    box-shadow: 4px 4px 12px -4px #000;
    border-radius: 20px;

    @media (max-width: 1080px) {
      margin-left: 0;
      margin-top: 12px;
      width: 343.8px; // 573 * 0.6
      height: 276px; // 460 * 0.6
    }

    & + img {
      margin-left: 80px;

      @media (max-width: 1080px) {
        margin-left: 0;
        margin-top: 12px;
      }
    }
  }
`

export const VisualContainer = styled.div`
  margin: 120px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 960px;
  position: relative;

  strong {
    font-size: 40px;
    color: #fff;
  }

  &:before {
    content: '';
    position: absolute;
    top: -60px;
    left: -1000px;
    right: -1000px;
    bottom: -60px;
    background: #5B027B;
    z-index: -1;
  }

  @media (max-width: 1080px) {
    width: auto;
  }
`

export const VisualTutorial = styled.div`
  margin-top: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1080px) {
    flex-direction: column;
  }
`

export const VisualTutorialDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  
  @media (max-width: 1080px) {
    margin-right: 0;
    margin-bottom: 40px;
    padding: 0 28px;
  }
`

export const VisualTutorialDescriptionText = styled.div`
  display: flex;

  strong {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.6);
  }

  h1 {
    margin-left: 8px;
    font-size: 18px;
    color: #fff;
  }

  & + div {
    margin-top: 12px;
  }
`

export const QualityContainer = styled.div`
  display: flex;
  margin: 100px auto 0;

  @media (max-width: 1080px) {
    flex-direction: column-reverse;
  }
`

export const QualityCTASection = styled.div`
  display: flex;
  flex-direction: column;

  strong {
    font-size: 36px;
    line-height: 52px;
    padding-left: 20px;

    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(to right, #027B5B, #fff);
      width: 8px;
    }

    @media (max-width: 1080px) {
      font-size: 28px;
      line-height: 40px;
      margin-top: 36px;
    }
  }
`

export const QualityCTAButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 240px;
  background: #027B5B;
  margin-top: 20px;
  border-radius: 8px;

  cursor: pointer;

  h1 {
    font-size: 24px;
    color: #fff;
  }

  &:hover {
    transition-duration: 0.3s;
    box-shadow: 0px 0px 20px rgba(2, 123, 91, 0.8);
    background: #fff;

    h1 {
      color: #027B5B;
    }
  }

  @media (max-width: 1080px) {
    margin: 20px auto 0;
  }
`

export const QualitiesSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 160px;

  @media (max-width: 1080px) {
    margin-left: 0;
    padding: 0 12px;
  }
`

export const Quality = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  div {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    max-width: 412px;

    strong {
      color: #5B027B;
      font-size: 24px;
    }

    h1 {
      color: #000;
      font-size: 16px;

      @media (max-width: 1080px) {
        font-size: 14px;
      }
    }
  }

  & + div {
    margin-top: 52px;

    &:before {
      content: '';
      position: absolute;
      top: -25.3px;
      left: 0;
      right: 0;
      height: 2px;
      background: linear-gradient(to right, #5B027B, #fff);
    }
  }
`

export const FAQTitle = styled.strong`
  margin: 52px auto 0;
  font-size: 40px;

  @media (max-width: 1080px) {
    text-align: center;
    padding: 0 8px;
  }
`

export const FAQContainer = styled.div`
  margin: 72px auto 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 960px;

  @media (max-width: 1080px) {
    flex-direction: column;
    width: auto;
  }
`

export const Question = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 300px;
  margin-bottom: 28px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background: rgba(2, 123, 91, 0.3);
  }

  strong {
    margin-top: 8px;
    font-size: 16px;
  }

  h1 {
    margin-top: 12px;
    font-size: 13px;

    & + h1 {
      margin-top: 2px;
    }
  }

  @media (max-width: 1080px) {
    margin-bottom: 36px;
  }
`

export const Footer = styled.div`
  margin-top: 72px;
  box-shadow: -4px 0 56px 4px rgba(2, 123, 91, 0.8);
  border-top: 2px solid #027B5B;
`

export const FooterContainer = styled.div`
  width: 920px;
  margin: 20px auto;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1080px) {
    width: auto;
    align-items: end;
    padding: 0 8px;
  }
`

export const FooterRightsReserved = styled.div`
  display: flex;
  flex-direction: column;

  strong {
    font-size: 32px;
    color: #027B5B;
  }

  h1 {
    font-size: 12px;
    color: #6a6a6a;
    margin-top: -4px;
  }
`

export const FooterSocialMedia = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 120px;

  a {
    &:hover {
      transition: 0.3s;
      transform: scale(1.2);
    }
  }

  @media (max-width: 1080px) {
    width: auto;
  }
`

// export const PaymentTitle = styled.strong`
//   /* margin: 52px auto 0; */
//   font-size: 48px;
//   color: #fff;

//   @media (max-width: 1080px) {
//     text-align: center;
//     padding: 0 8px;
//   }
// `

// export const PaymentSubTitle = styled.strong`
//   margin-top: 24px;
//   font-size: 20px;
//   color: #fff;

//   @media (max-width: 1080px) {
//     text-align: center;
//     padding: 0 8px;
//   }
// `

// export const PaymentContainer = styled.div`
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   height: 92vh;
//   width: 100vw;
//   background: rgba(2, 123, 91, 1); // 0.8

//   @media (max-width: 1080px) {
//     flex-direction: column;
//     padding: 12px 0;
//     height: 100%;
//   }
// `

// export const PaymentMethods = styled.div`
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-top: 60px;

//   @media (max-width: 1080px) {
//     flex-direction: column;
//     padding: 12px 0;
//     height: 100%;
//   }
// `

// export const Card = styled.div`
//   padding: 20px 0 28px;
//   height: 360px;
//   width: 260px;
//   border-radius: 12px;
//   /* background: #fff; */
//   background: linear-gradient(to top left, #027B5B, #fff);
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   z-index: 1;
//   position: relative;
//   box-shadow: 0px 4px 12px 0px #000;


//   h1 {
//     font-size: 18px;

//     & + h1 {
//       font-size: 10px;
//       opacity: 0.6;
//       letter-spacing: 1.2px;
//       text-transform: uppercase;
//     }
//   }

//   &:nth-child(2) {
//     /* background: #111727; */
//     background: linear-gradient(to bottom right, #111727, #fff);
//     height: ${360 * 1.1}px;
//     width: ${260 * 1.1}px;
//     margin: 0 -6px;
//     z-index: 10;

//     h1 {
//       color: #fff;
//     }

//     @media (max-width: 1080px) {
//       height: 300px;
//       width: 260px;
//       margin: 12px 0 0 0;
//     }
//   }

//   @media (max-width: 1080px) {
//     height: 300px;

//     & + div {
//       margin-top: 12px;
//     }
//   }
// `

// export const PopularTag = styled.div`
//   height: 16px;
//   width: 60px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 8px;
//   background: #fff;
//   box-shadow: 0px 0px 12px -1px #fff;

//   position: absolute;
//   top: 12px;
//   right: 12px;

//   strong {
//     font-size: 12px;
//     color: #111727;
//   }
// `

// export const Price = styled.div`
//   display: flex;
//   align-items: flex-start;
//   margin-top: ${props => props.principal ? 92 : 80}px;

//   strong {
//     font-size: 44px;
//     color: ${props => !props.principal ? '#111727' : '#fff'};
//     position: relative;


//     &:before {
//       content: '';
//       position: absolute;
//       bottom: -12px;
//       left: 12px;
//       right: 12px;
//       background: ${props => props.principal ? 'linear-gradient(to left, #111727, #fff)' : 'linear-gradient(to right, #027B5B, #fff)'};
//       height: 1px;
//     }

//     &:after {
//       content: '';
//       position: absolute;
//       bottom: -20px;
//       left: 24px;
//       right: 24px;
//       background: ${props => props.principal ? 'linear-gradient(to left, #111727, #fff)' : 'linear-gradient(to right, #027B5B, #fff)'};
//       height: 1px;
//     }
//   }

//   h1 {
//     font-size: 12px;
//     color: ${props => !props.principal ? '#111727' : '#fff'};
//   }

//   @media (max-width: 1080px) {
//     margin-top: 52px;
//   }
// `

// export const CTAButton = styled.div`
//   padding: 6px 20px;
//   border-radius: 8px;
//   background: ${props => !props.principal ? '#111727' : '#fff'};
//   margin-top: auto;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   cursor: pointer;

//   strong {
//     font-size: ${props => props.principal ? 20 : 18}px;
//     color: ${props => props.principal ? '#111727' : '#fff'};

//     @media (max-width: 1080px) {
//       font-size: 20px;
//     }
//   }

//   &:hover {
//     transition: 0.3s;
//     transform: scale(1.06);
//   }
// `