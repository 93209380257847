import { createContext, useContext, useState } from "react";
import { collection, getDocs, setDoc, doc } from 'firebase/firestore'

import { db } from '../services/firebase'

const usersCollection = collection(db, 'users')

const BotContext = createContext({})

export const BotProvider = ({ children }) => {
  const [data, setData] = useState(
    {
      botInfo: null,
      chatId: '',
      isSubscribed: false
    }
  )

  const getBot = async (userId) => {
    const data = await getDocs(usersCollection)

    const dataFormmatted = {}

    for (let x = 0 ; x < data.docs.length ; x++) {
      const newObj = {
        [data.docs[x].id]: {
          ...data.docs[x].data()
        }
      }

      Object.assign(
        dataFormmatted,
        newObj
      )
    }

    if (dataFormmatted[userId]) {
      setData(dataFormmatted[userId])
    }

    return dataFormmatted[userId]
  }

  const createBot = async (userId, botInfo, chatId, isSubscribed) => {
    const updateData = {
      botInfo,
      chatId: chatId === '' ? chatId : data.chatId,
      isSubscribed: isSubscribed === false ? isSubscribed : data.isSubscribed,
    }

    await setDoc(doc(usersCollection, userId), updateData)

    setData(updateData)
  }

  const deleteBot = async (userId) => {
    const updateData = {
      botInfo: null,
      chatId: data.chatId,
      isSubscribed: data.isSubscribed
    }

    await setDoc(doc(usersCollection, userId), updateData)

    setData(updateData)
  }

  const deleteTelegram = async (userId) => {
    const updateData = {
      botInfo: data.botInfo,
      chatId: '',
      isSubscribed: data.isSubscribed
    }

    await setDoc(doc(usersCollection, userId), updateData)

    setData(updateData)
  }

  const updateSubscription = async (userId, isSubscribed) => {
    const updateData = {
      botInfo: data.botInfo,
      chatId: data.chatId,
      isSubscribed
    }

    await setDoc(doc(usersCollection, userId), updateData)

    setData(updateData)
  }

  return (
    <BotContext.Provider
      value={{
        bot: data,
        getBot,
        createBot,
        deleteBot,
        deleteTelegram,
        updateSubscription
      }}
    >
      {children}
    </BotContext.Provider>
  )
}

export const useBot = () => {
  const context = useContext(BotContext);

  if (!context) {
    // se o BotContext ñ foi criado, ou seja, caso não haja .Provider ao redor
    throw new Error('The context must be used within an .Provider');
  }

  return context;
}