import React, { useEffect } from "react";
import Lottie from "lottie-react";

import { lotties } from "../../assets";

import { Container, Content, LottieContainer } from "./styles";

import { useBot } from "../../hooks/bot";
import { useAuth } from "../../hooks/auth";

const Success = () => {
  const { user } = useAuth()
  const { updateSubscription } = useBot()

  useEffect(() => {
    const auxFunction = async () => {
      await updateSubscription(user?.uid, true)
    }

    auxFunction()
  }, [updateSubscription, user?.uid])

  return (
    <Container>
      <Content>
        <LottieContainer>
          <Lottie
            style={{
              width: 220
            }}
            loop={true}
            autoplay={true}
            animationData={lotties.success}
          />
        </LottieContainer>

        <strong>Sucesso</strong>

        <h1>Recebemos sua requisição de compra, em instantes sua conta estará liberada para uso!</h1>
      </Content>
    </Container>
  )
}

export default Success