import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  
  body {
    // background: #312E38;
    color: #000;
    -webkit-font-smoothing: antialiased;
    user-select: none; // disable text selection highlighting in Google Chrome
  }
  body, input, button {
    font-family: 'Roboto Slab', serif;
    font-size: 16px;
  }
  strong, a {
    font-weight: 600;
    font-family: 'Nunito', sans-serif;
  }

  h1, input {
    font-weight: 500;
    font-family: 'Nunito', sans-serif;
  }
  
  button {
    cursor: pointer;
  }
`;