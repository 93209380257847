import React, { createContext, useState, useContext, useEffect } from 'react';
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import { useBot } from './bot'
import { auth } from '../services/firebase';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const { getBot, createBot } = useBot()

  const [user, setUser] = useState(() => {
    const user = localStorage.getItem('@RoboX:user');

    if (user) {
      return JSON.parse(user)
    }

    return null;
  });

  useEffect(() => {
    const func = async () => {
      const isUserExist = await getBot(user.uid)
      
      if (!isUserExist) {
        localStorage.removeItem('@RoboX:user')

        setUser(null)
      }
    }
    
    if (user) func() // for when the user is registered in the localStorage, but for some reason it's not registered anymore in firebase
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const signIn = async () => {
    const provider = new GoogleAuthProvider();

    const response = await signInWithPopup(auth, provider)
      .then(async (result) => {
        const isUserExist = await getBot(result.user.uid)

        if (isUserExist) {
          setUser(result.user)
          localStorage.setItem('@RoboX:user', JSON.stringify(result.user))

          return {
            user: result.user,
            bot: isUserExist
          }
        } else {
          alert("O usuário não existe!")
        }
      }).catch((error) => {
        console.log(error);
      });
    
    return response
  }

  const signUp = async () => {
    const provider = new GoogleAuthProvider();

    await signInWithPopup(auth, provider)
      .then(async (result) => {
        const isUserExist = await getBot(result.user.uid)

        if (isUserExist) {
          alert("O usuário já está cadastrado!")

          return
        }

        await createBot(result.user.uid, null, '', false)

        setUser(result.user);

        localStorage.setItem('@RoboX:user', JSON.stringify(result.user));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const signOut = () => {
    localStorage.removeItem('@RoboX:user')

    setUser(null)
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        signIn,
        signUp,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('The context must be used within an .Provider');
  }

  return context;
}