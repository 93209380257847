import styled, { css, keyframes } from 'styled-components'

const sizeAnimation = keyframes`
  0% {
    width: 180px;
    height: 144px;
  }

  50% {
    width: 240px;
    height: 192px;
  }

  100% {
    width: 180px;
    height: 144px;
  }
`

const sizeAnimationMobile = keyframes`
  0% {
    width: 144px;
    height: 115px;
  }

  50% {
    /* width: 192px;
    height: 153px; */

    width: 172px;
    height: 138px;
  }

  100% {
    width: 144px;
    height: 115px;
  }
`

export const Container = styled.div`
  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  display: flex;
  box-shadow: 0 4px 16px -1px rgba(0, 0, 0, 0.6);
  width: 100vw;
  padding: 8px 0;
  align-items: center;
  justify-content: center;
  background: #fff;
`

export const SignOutButton = styled.div`
  position: absolute;
  right: 140px;
  cursor: pointer;

  strong {
    font-size: 16px;
    color: #027B5B;
  }

  padding: 4px 16px;
  border: 1px solid #027B5B;
  border-radius: 52px;

  &:hover {
    transition-duration: 0.3s;

    background: #027B5B;

    strong {
      color: #fff;
    }
  }

  @media (max-width: 1080px) {
    right: 12px;
  }
`

export const WelcomeText = styled.strong`
  font-size: 44px;
  color: #027B5B;
  align-self: center;
  margin-top: 52px;

  @media (max-width: 1080px) {
    font-size: 36px;

    & + strong {
      margin-top: 0;
    }
  }
`

export const SquareContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: center;
  margin-top: 80px;

  @media (max-width: 1080px) {
    justify-content: center;
    flex-direction: column;
    margin-top: 32px;
  }
`

export const SquareBot = styled.div`
  height: 320px;
  width: 420px;
  border: 1px solid #027B5B;
  border-radius: 20px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 28px 0;

  cursor: pointer;

  strong {
    font-size: 32px;
    position: relative;
    color: #027B5B;

    &:before {
      content: '';
      position: absolute;
      left: 24px;
      right: 24px;
      bottom: -4px;
      height: 2px;
      background: #027B5B;
    }

    @media (max-width: 1080px) {
      font-size: 25px;
    }
  }

  ${props =>
    !props.isBotConfigured &&
    css`
      svg {
        width: 180px;
        height: 144px;
        color: #027B5B;
      
        animation-name: ${sizeAnimation};
        animation-duration: 4s;
        animation-iteration-count: infinite;

        @media (max-width: 1080px) {
          width: 144px;
          height: 115px;

          animation-name: ${sizeAnimationMobile};
        }
      }
    `
  }

  &:hover {
    transition-duration: 0.3s;
    background: #027B5B;

    strong {
      color: #fff;

      &:before {
        background: #fff;
      }
    }

    svg {
      color: #fff;
    }
  }

  @media (max-width: 1080px) {
    height: 256px; // 320 * 0.8
    width: 336px; // 420 * 0.8
    margin-bottom: 32px;
  }
`

export const SquareBotBlocked = styled.div`
  height: 320px;
  width: 420px;
  border: 1px solid #027B5B;
  border-radius: 20px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 16px;

  h1 {
    color: #027B5B;
    font-size: 32px;
    text-align: center;

    @media (max-width: 1080px) {
      font-size: 25px;
    }
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 20px;
    z-index: -1;
    filter: blur(12px);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1080px) {
    height: 256px; // 320 * 0.8
    width: 336px; // 420 * 0.8
    margin-bottom: 32px;
  }
`

export const RightSection = styled.div`
  margin-left: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1080px) {
    margin-left: 0;
  }
`

export const SquareTelegram = styled.div`
  height: 60px;
  width: 400px;
  border: 1px solid #5B027B;
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.isClickable &&
    css`
      cursor: pointer;
    `
  }

  a, strong {
    font-size: 22px;
    margin-left: 8px;
    color: #5B027B;
    text-decoration: none;

    @media (max-width: 1080px) {
      font-size: 20px;
    }
  }

  @media (max-width: 1080px) {
    width: 336px;
  }
`

export const SquareSubscription = styled.div`
  height: 60px;
  width: 400px;
  border: 1px solid #cf142b;
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 8px;

  cursor: pointer;

  strong {
    font-size: 22px;
    margin-left: 8px;
    color: #cf142b;
    text-decoration: none;

    ${props =>
      !props.isSubscribed &&
      css`
        color: #5B027B;
      `
    }

    @media (max-width: 1080px) {
      font-size: 20px;
    }
  }

  ${props =>
    !props.isSubscribed &&
    css`
      border: 1px solid #5B027B;
    `
  }

  @media (max-width: 1080px) {
    width: 336px;
    margin-top: 8px;
  }
`

export const SquareRemove = styled.div`
  height: 60px;
  width: 400px;
  border: 1px solid #cf142b ;
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 8px;

  cursor: pointer;

  strong {
    font-size: 22px;
    margin-left: 8px;
    color: #cf142b;
    text-decoration: none;

    @media (max-width: 1080px) {
      font-size: 20px;
    }
  }

  ${props =>
    (props.isTelegramEmpty || props.isBotEmpty) &&
    css`
      filter: blur(4px);
      cursor: auto;
    `
  }

  @media (max-width: 1080px) {
    width: 336px;
    margin-top: 8px;

    & + div {
      margin-bottom: 28px;
    }
  }
`