import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FiArrowDownCircle, FiArrowUpCircle } from 'react-icons/fi'
import { ComparationOption, ComparationOptionContainer, ComparationSection, Container, Content, FooterSection, QuantitySection, StatisticOption, StatisticOptionContainer, StatisticSection, TeamOption, TeamSection } from './styles'
 
const statistics = [
  'Gols', 
  'Total de chutes', 
  'Defesas do goleiro', 
  'Chutes para fora', 
  'Escanteios', 
  'Faltas', 
  'Chutes no gol', 
  'Posse de bola', 
  'Cartões amarelos', 
  'Chutes dentro da área', 
  'Precisão dos passes', // Talvez colocar Chutes fora da área
  'Cartões vermelhos'
]
 
const ModalFilter = ({
  setIsModalOpen,
  setFilter // { team, statisticType, comparation, quantity }
}) => {
  const [team, setTeam] = useState('Casa')
  const [statistic, setStatistic] = useState('Gols')
  const [comparation, setComparation] = useState('Menor que')
  const [quantity, setQuantity] = useState(0)
 
  useEffect(() => {
    if (statistic === 'Posse de bola' || statistic === 'Precisão dos passes') {
      setQuantity(50)
    } else {
      setQuantity(0)
    }
  }, [statistic])
 
  const quantityFormatted = useMemo(() => {
    if (statistic === 'Posse de bola' || statistic === 'Precisão dos passes') {
      return quantity + '%'
    } else {
      return quantity
    }
  }, [quantity, statistic])
 
  const handleIncreaseQuantity = useCallback(() => {
    if (statistic === 'Posse de bola' || statistic === 'Precisão dos passes') {
      if (quantity < 99) {
        setQuantity(quantity + 1)
      }
    } else {
      if (quantity < 9) {
        setQuantity(quantity + 1)
      }
    }
  }, [quantity, statistic])
 
  const handleDecreaseQuantity = useCallback(() => {
    if (quantity > 0) {
      setQuantity(quantity - 1)
    }
  }, [quantity])
 
  const addFilter = useCallback(() => {
    setFilter(filter => 
      [
        ...filter, 
        {
          team,
          comparation,
          statisticType: statistic,
          quantity
        }
      ]
    )
 
    setIsModalOpen(false)
  }, [setFilter, setIsModalOpen, team, comparation, statistic, quantity])
 
  return (
    <Container>
      <Content>
        <TeamSection>
          <strong>Selecione o time</strong>
 
          <div>
            {['Casa', 'Visitante', 'Juntos'].map(elem => (
              <TeamOption
                selected={elem === team}
                onClick={() => setTeam(elem)}
                key={elem}
              >
                <strong>{elem}</strong>
              </TeamOption>
            ))}
          </div>
        </TeamSection>
 
        <StatisticSection>
          <strong>Escolha a ação</strong>
 
          <StatisticOptionContainer>
            {statistics.map((elem, index) => (
              <StatisticOption
                selected={elem === statistic}
                onClick={() => setStatistic(elem)}
                key={elem}
              >
                <strong>{elem}</strong>
              </StatisticOption>
            ))}
          </StatisticOptionContainer>
        </StatisticSection>
 
        <ComparationSection>
          <strong>Selecione a comparação</strong>
 
          <ComparationOptionContainer>
            {['Menor que', 'Igual a', 'Maior que'].map(elem => (
              <ComparationOption
                selected={elem === comparation}
                onClick={() => setComparation(elem)}
                key={elem}
              >
                <strong>{elem}</strong>
              </ComparationOption>
            ))}
          </ComparationOptionContainer>
        </ComparationSection>
 
        <QuantitySection>
          <strong>Quantidade de <strong>{statistic}</strong></strong>
 
          <div>
            <FiArrowDownCircle
              cursor='pointer'
              color={quantity === 0 ? 'rgba(2, 123, 91, 0.32)' : '#027B5B'}
              onClick={handleDecreaseQuantity}
              size={28}
            />
 
            <strong>{quantityFormatted}</strong>
 
            <FiArrowUpCircle
              cursor='pointer'
              color={quantity === 9 ? 'rgba(2, 123, 91, 0.32)' : '#027B5B'}
              onClick={handleIncreaseQuantity}
              size={28}
            />
          </div>
        </QuantitySection>
 
        <FooterSection>
          <h1
            onClick={() => setIsModalOpen(false)}
          >
            Cancelar
          </h1>
 
          <div
            onClick={addFilter}
          >
            <strong>Salvar</strong>
          </div>
        </FooterSection>
      </Content>
    </Container>
  )
}
 
export default ModalFilter